import React from 'react';
import { Card } from 'flowbite-react';

const Reference = ({ Image, TitleJob, DescriptionJob }) => {
  return (
    <Card className="max-w-sm transition duration-500 hover:scale-105">
      <img src={Image} alt={TitleJob} className="object-cover w-full h-50 p-2" />
      <div className="p-4">
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white mb-2">
          {TitleJob}
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          {DescriptionJob}
        </p>
      </div>
      </Card>
  );
};

export default Reference;