import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navigation from './base/Navigation';
import Home from './pages/Home';

const App = () => {
    return (
        <Router>
            <section className="pt-[60px]">
                <div>
                    <Navigation />
                </div>
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </section>
        </Router>
    );
};

export default App;