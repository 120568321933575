import React from 'react';
import Service from './Service';
import securite_incendie from '../images/securite_incendie.png';
import sauvetage_secourisme from '../images/sauvetage_secourisme.png';
import procedures_evacuation from '../images/procedures_evacuation.png';
import etude_audit_securite_incendie_panique from '../images/etude_audit_securite_incendie_panique.png';
import formation_haccp from '../images/formation_haccp.png';
import formation_caces from '../images/formation_caces.png';
import equivalent_ssiap_maroc from '../images/equivalent_ssiap_maroc.png';
import travaux_hauteur from '../images/travaux_hauteur.png';
import elaboration_notices_securite from '../images/elaboration_notices_securite.png';
import techniques_specifiques from '../images/techniques_specifiques.png';

const Services = () => {
  return (
    <div id="Services" className="flex justify-around p-10 flex-wrap dark:bg-blue4">
      <h1 className="mb-10 text-4xl font-extrabold tracking-tight leading-none text-black md:text-5xl lg:text-6xl dark:text-white">
        Services
      </h1>
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="flex justify-around flex-wrap">
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
            <Service
              Image={securite_incendie}
              TitleJob="Fire Safety"
              DescriptionJob="Contribute to securing the establishment; Be able to extinguish a fire, adopt confident behavior in smoke, know how to use first intervention means (extinguishers, fire hose reel, fire hydrant...)."
              DescriptionClass="p-4"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
            <Service
              Image={sauvetage_secourisme}
              TitleJob="Rescue and First Aid"
              DescriptionJob="This initial SST training prepares the occupational first aider to intervene quickly and effectively in a workplace accident situation within the establishment or profession. This training provides the knowledge to provide first aid and the actions to take while waiting for emergency services."
              DescriptionClass="p-4"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
            <Service
              Image={procedures_evacuation}
              TitleJob="Evacuation Procedures"
              DescriptionJob="Identify regulations that impose safety instructions according to the specificities of each establishment. Know how to act when discovering an initial fire and raise the alarm, know the precautions to be taken during an evacuation."
              DescriptionClass="p-4"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
            <Service
              Image={etude_audit_securite_incendie_panique}
              TitleJob="Fire Safety, Panic Study and Audit"
              DescriptionJob="Understand the mechanisms of fire. Know the role and responsibilities of different prevention actors, building classification, general and specific provisions of fire safety regulations against types of risks in ERP, IGH, ICPE, ERT... In collaboration with architects."
              DescriptionClass="p-4"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
            <Service
              Image={formation_haccp}
              TitleJob="HACCP Training"
              DescriptionJob="Know how to detect and control contamination and microbial development risks; Analyze biological hazards. Implement corrective actions and measures. Know how to build an HACCP plan in its 12 steps."
              DescriptionClass="p-4"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
            <Service
              Image={equivalent_ssiap_maroc}
              TitleJob="CACES Training"
              DescriptionJob="Raise awareness among forklift drivers about accident and incident prevention. Learn about the types of machinery. Be able to drive machinery in compliance with safety regulations. This training takes place in a specific exercise area."
              DescriptionClass="p-4"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
            <Service
              Image={formation_caces}
              TitleJob="SSIAP Equivalent in Morocco"
              DescriptionJob="Master the regulatory and practical knowledge required for fire safety and assistance services. Know the legal and regulatory bases of the function, acquire specialized technical instruction in fire prevention, detection, firefighting, and maintenance of rescue means."
              DescriptionClass="p-4"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
            <Service
              Image={travaux_hauteur}
              TitleJob="Working at Height"
              DescriptionJob="Acquire the knowledge and skills to work safely at height. Know how to assess risks in a work at height situation. Identify and choose the necessary equipment for the work environment. Know the fall arrest systems, check them before use, adjust them, and know how to equip oneself to optimize movements."
              DescriptionClass="p-4"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
            <Service
              Image={elaboration_notices_securite}
              TitleJob="Safety Notice Elaboration"
              DescriptionJob="Fire safety notice to be carried out following the security commission's passage prescribing works as part of the building's safety. The notice ensures the necessary prescriptions for the project's good understanding and mentions the measures taken to comply with fire safety regulations, approving the general construction rules."
              DescriptionClass="p-4"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
            <Service
              Image={techniques_specifiques}
              TitleJob="Specific Techniques"
              DescriptionJob="Specific technical studies (SSI, smoke extraction, electricity, evacuation, accessibility) at the request of the Manager in order to possibly carry out future works in his establishment in a regulatory manner (technical diagnosis)."
              DescriptionClass="p-4"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
