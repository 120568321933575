import Jumbotron from "../components/Jumbutron";
import Services from "../components/Services";
import Foot from "../base/Footer";
import Contact from "../components/Contact";
import References from "../components/References";

const Home = () => {
    return (
      <>
      <Jumbotron/>
      <Services />
      <References />
      <Contact />
      <Foot/>
      </>
    );
  }
  
  export default Home;