import React from 'react';
import slider from '../images/slider.png';

const Jumbotron = () => {
  return (
    <section id="Home" className="relative bg-center bg-no-repeat bg-cover bg-gray-700 bg-blend-multiply" style={{ backgroundImage: `url(${slider})`, height: '100vh' }}>
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="px-4 mx-auto max-w-screen-xl text-center">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">Enhancing Safety with Expertise</h1>
          <p className="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">At Prevenrisk Africa, we specialize in ensuring safety through advanced strategies and comprehensive solutions. Our focus is on leveraging expertise to mitigate risks effectively, ensuring secure environments for our clients across diverse sectors.</p>
          <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
            <a href="#Services" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
              Check our services
              <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Jumbotron;
