import React from 'react';
import Reference from './Reference';
import logo_aap from '../images/logo_aap.png';
import logo_grp_accor from '../images/logo_grp_accor.png';
import logo_kenzi from '../images/logo_kenzi.png';
import logo_managem from '../images/logo_managem.png';
import logo_multicerame from '../images/logo_multicerame.png';
import logo_sanofi from '../images/logo_sanofi.png';
import logo_silverfood from '../images/logo_silverfood.png';
import logo_somadir from '../images/logo_somadir.png';
import logo_hilton from '../images/logo_hilton.png';
import logo_michoc from '../images/logo_michoc.png';
import logo_onomo from '../images/logo_onomo.png';
import logo_lecasablanca from '../images/logo_lecasablanca.png';
import logo_vichymy from '../images/logo_vichymy.png';
import logo_petrom from '../images/logo_petrom.png';
import logo_atlaspharm from '../images/logo_AtlasPharm.png';
import logo_atelier1 from '../images/logo_atelier1.png';
import logo_koutoubia from '../images/logo_koutoubia.png';

const References = () => {
  return (
    <div className="bg-red-600 dark:bg-blue5">
      <div id="References" className="flex justify-around p-10 flex-wrap">
      <h1 className="mb-10 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl dark:text-white">
        References
      </h1>
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="flex justify-around flex-wrap">
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_hilton}
                TitleJob="Hilton Group"
                DescriptionJob="Hotel group"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_atlaspharm}
                TitleJob="Atlas Pharm"
                DescriptionJob="Pharmaceutical laboratory"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_michoc}
                TitleJob="Michoc"
                DescriptionJob="manufacturing and marketing of confectionery"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_koutoubia}
                TitleJob="Koutoubia"
                DescriptionJob="agri-food sector, specialized in charcuterie"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_onomo}
                TitleJob="Onomo"
                DescriptionJob="Hotel group"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_lecasablanca}
                TitleJob="Le Casablanca Hotel"
                DescriptionJob="Hotel"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_vichymy}
                TitleJob="Vichy Thermalia Spa Hotel"
                DescriptionJob="Hotel group"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_petrom}
                TitleJob="Petrom Maroc"
                DescriptionJob="Fuel company"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_grp_accor}
                TitleJob="Accor Group"
                DescriptionJob="Hotel group"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_aap}
                TitleJob="APP"
                DescriptionJob="Agency of partnerships for progress"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_kenzi}
                TitleJob="Kenzi Hotel Group"
                DescriptionJob="Hotel group"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_atelier1}
                TitleJob="Atelier 1"
                DescriptionJob="Architectural group"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_managem}
                TitleJob="Managem"
                DescriptionJob="Mining group"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_multicerame}
                TitleJob="Multicerame"
                DescriptionJob="Industrial company"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_sanofi}
                TitleJob="Sanofi Aventis"
                DescriptionJob="Pharmaceutical laboratory"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_silverfood}
                TitleJob="Silver Food"
                DescriptionJob="Fish canning"
                DescriptionClass="p-4"
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 mb-10 px-2">
              <Reference
                Image={logo_somadir}
                TitleJob="Somadir"
                DescriptionJob="Yeast producer"
                DescriptionClass="p-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default References;
