import { Footer } from 'flowbite-react';
import { BsFacebook,BsInstagram, BsTwitter } from 'react-icons/bs';
import logo from "../images/logo.png"

const Foot = () => {
  return (
    <div className="dark:bg-blue4">
      <Footer container className="bg-blue-50" style={{ marginTop: '4dp' }}>
        <div className="w-full">
          <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
            <div>
              <Footer.Brand className='w-full flex justify-center items-center py-4'href="#">
                <img src= {logo} className="h-6 sm:h-9 pt-1" alt="Prevenrisk Africa Logo" />
                <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">Prevenrisk Africa</span>
              </Footer.Brand>
            </div>
          </div>
          <Footer.Divider />
          <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
            <Footer.Copyright href="#" by="hvfidolov" year={2024} />
            <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
              <Footer.Icon href="#" icon={BsFacebook} />
              <Footer.Icon href="#" icon={BsInstagram} />
              <Footer.Icon href="#" icon={BsTwitter} />
            </div>
          </div>
        </div>
      </Footer>
    </div>
  );
};

  export default Foot ;